import React from 'react';
import '../../style.scss';
import { useTranslation } from 'react-i18next';
import { Screen } from '../../../../_constants';
import { ResponsiveContext } from '../../../../_store/responsive-context';
import TableRow from './TableRow';
import FlexRow from './FlexRow';

export default ({item, index, errors, touched}) => {
    const {t} = useTranslation('orders');

    return (
        <ResponsiveContext.Consumer>
            {({size}) => (
                <>
                    {size >= Screen.md && <TableRow item={item} index={index} errors={errors} touched={touched} t={t} />}
                    {size < Screen.md && <FlexRow item={item} index={index} errors={errors} touched={touched} t={t} />}
                </>
            )}
        </ResponsiveContext.Consumer>
    );
}