import React from 'react';
import '../../style.scss';
import _ from 'lodash';
import { TextInput } from 'CommonUI/FormFields';
import { Field } from 'formik';


export default ({item, index, errors, touched, t }) => (
    <div className="row">
        <div className="col-6">{t('name')}:</div>
        <div className="col-6">{item.name}</div>
        <div className="col-6">{t('ref_fab')}:</div>
        <div className="col-6">{item.reference}</div>
        <div className="col-6">{t('brand')}:</div>
        <div className="col-6">{item.brand}</div>
        <div className="col-6">{t('ordered_quantity')}</div>
        <div className="col-6">
            <Field className="span-input" disabled name={`products.${index}.ordered_quantity`}/>
        </div>
        <div className="col-6">{t('already_received_quantity')}</div>
        <div className="col-6">
            <Field className="span-input" disabled name={`products.${index}.already_received_quantity`}/>
        </div>
        <div className="col-6">{t('received_quantity')}</div>
        <div className="col-6 received-quantity">
            <TextInput
                type="text"
                name={`products.${index}.quantity`}
                error={_.isArray(errors.products) && errors.products[index] ? t(errors.products[index].quantity) : ''}
                touched={_.isArray(touched.products) && touched.products[index] ? touched.products[index].quantity : false}
            />
            <TextInput
                type="hidden"
                name={`products.${index}.id`}
            />
        </div>
    </div>
);